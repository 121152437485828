<template>
  <div class="howitwork">
    <page-header heading="How it works" desc="Embark upon the journey of learning in just a few easy steps" />
    <b-card no-body>
    <b-tabs card>
      <b-tab no-body title="Exams">
        <div class="container">
        <b-card-img class="d-none d-sm-block" bottom src="/assets/images/examflow.svg" alt="KIPS Virtual"></b-card-img>
        <b-card-img class="d-block d-sm-none" bottom src="/assets/images/examflowresponsive.svg" alt="KIPS Virtual"></b-card-img>
      </div>
      </b-tab>

      <b-tab no-body title="Class">
        <div class="container">
        <b-card-img class="d-none d-sm-block" bottom src="/assets/images/classflow.svg" alt="KIPS Virtual"></b-card-img>
        <b-card-img class="d-block d-sm-none" bottom src="/assets/images/classflowresponsive.svg" alt="KIPS Virtual"></b-card-img>
      </div>
      </b-tab>
    </b-tabs>
  </b-card>
  <appFooter />
  </div>
</template>

<script>
import Footer from "../layout/landing/appFooter.vue";
import pageHeader from "../components/pageHeader.vue";
export default {
  components: {
    appFooter: Footer,
    pageHeader,
  },
  metaInfo: {
    title: "How it Works | KIPS VIRTUAL",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  asyncData({ store, route }) {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
</style>